import React from "react";
import queryString from "query-string";
import CreateEditQuiz from "../components/CreateEditQuiz";
import PageWrapper from "../components/PageWrapper";

export default function CreateEditQuizPage({ location }) {
  const { quizId } = queryString.parse(location.search);

  return (
    <PageWrapper>
      <CreateEditQuiz key={quizId} quizId={quizId} />
    </PageWrapper>
  );
}
