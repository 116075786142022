import React, { useContext, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Formik, useFormikContext } from "formik";
import { navigate } from "gatsby";
import styled from "styled-components";
import ContentBox from "../components/ContentBox";
import Form from "../components/Form";
import FormField from "../components/FormField";
import Label from "../components/Label";
import Input from "../components/Input";
import FormError from "../components/FormError";
import Button from "../components/buttons/Button";
import FormHint from "../components/FormHint";
import Link from "../components/Link";
import ActionBar from "../components/OldActionBar";
import DangerButton from "../components/buttons/DangerButton";
import Modal from "../components/Modal";
import { Drag } from "grommet-icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DEBUG } from "../flags";
import GetLinkModal from "../components/modals/GetLinkModal";
import ResetQuizModal from "../components/modals/ResetQuizModal";
import sleep from "../util/sleep";
import DuplicateQuizModal from "../components/modals/DuplicateQuizModal";
import UserContext from "../contexts/UserContext";
import UserSelect from "./UserSelect";
import Bugsnag from "@bugsnag/js";
import { REGULAR_TEXT } from "../colors";
import DarkPageHeading from "./DarkPageHeading";

const GET_QUIZ = gql`
  query GetQuiz($id: ID) {
    quiz: getQuiz(id: $id) {
      name
      publicId
      owner {
        id
        username
      }
      started
      rounds {
        id
        number
        title
        questions {
          id
          number
          text
          answer
        }
      }
      tiebreaker {
        text
      }
    }
  }
`;

const HOST_QUIZ = gql`
  mutation HostQuiz($publicQuizId: String!) {
    hostQuiz(publicQuizId: $publicQuizId) {
      quiz {
        id
        name
      }
    }
  }
`;

const SAVE_QUIZ = gql`
  mutation SaveQuiz($id: ID, $name: String!, $ownerId: ID) {
    saveQuiz(id: $id, name: $name, ownerId: $ownerId) {
      quiz {
        id
        name
        publicId
      }
    }
  }
`;

const REORDER_ROUNDS = gql`
  mutation ReorderRounds($quizId: ID!, $roundId: ID!, $newNumber: Int!) {
    reorderRounds(quizId: $quizId, roundId: $roundId, newNumber: $newNumber) {
      quiz {
        id
        rounds {
          id
          number
          title
        }
      }
    }
  }
`;

const DELETE_QUIZ = gql`
  mutation DeleteQuiz($id: ID!) {
    deleteQuiz(id: $id) {
      success
    }
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const QuizStartedMessage = styled.div`
  font-size: 18px;
  color: red;
  margin-bottom: 20px;
`;

const UneditableValue = styled.div`
  color: ${REGULAR_TEXT};
  font-weight: bold;
`;

const RoundHeading = styled.div`
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 15px;
`;

const RoundListing = styled.div`
  margin-bottom: 10px;
  svg {
    opacity: 0;
  }

  ${({ disabled }) =>
    !disabled &&
    `
    :hover svg {
      opacity: 1;
    }
  `}
`;

const TiebreakerListing = styled(RoundListing)`
  margin-left: 20px;
`;

const DragWrapper = styled.span`
  padding: 15px 5px;

  :hover {
    cursor: grab;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
`;

function QuizEditor({
  quizId,
  publicQuizId,
  ownerId,
  ownerUsername,
  rounds,
  tiebreaker,
  originalValues,
  hosting,
  isStarted,
  onHostQuiz,
  onReorderRounds,
  onDelete,
}) {
  const { currentUser } = useContext(UserContext);

  const isSuperuser = currentUser && currentUser.isSuperuser;

  const {
    values,
    touched,
    errors,
    handleChange,
    submitForm,
    isSubmitting,
    setFieldValue,
  } = useFormikContext();

  const [showGetLinkModal, setShowGetLinkModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState();
  const [isDeleting, setIsDeleting] = useState();

  const isChanged = () => {
    if (values.quizName !== originalValues.name || values.ownerId !== ownerId) {
      return true;
    }
    return false;
  };

  const handleDragEnd = async (result) => {
    const roundId = result.draggableId;
    const newNumber = result.destination.index + 1;

    await onReorderRounds(roundId, newNumber);
  };

  const [showResetModal, setShowResetModal] = useState();

  const handleGetLinkClick = () => {
    setShowGetLinkModal(true);
  };

  const handleCloseGetLink = () => {
    setShowGetLinkModal(false);
  };

  const handleDuplicateClick = () => setShowDuplicateModal(true);
  const handleCloseDuplicate = () => setShowDuplicateModal(false);

  const handleResetClick = () => {
    setShowResetModal(true);
  };

  const handleCancelReset = () => {
    setShowResetModal(false);
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    await onDelete();
  };

  return (
    <ContentBox direction="column">
      <DarkPageHeading>
        {!quizId ? "Create a Quiz" : "Edit Quiz"}
      </DarkPageHeading>
      <FormWrapper>
        <Form>
          {isStarted && (
            <QuizStartedMessage>
              Quiz is not editable since it has already started.
            </QuizStartedMessage>
          )}
          <FormField>
            <Label htmlFor="quizName">Quiz Name</Label>
            <Input
              type="text"
              name="quizName"
              autoComplete="off"
              disabled={isStarted}
              value={values.quizName}
              onChange={handleChange}
            />

            {errors.quizName && touched.quizName && (
              <FormError>{errors.quizName}</FormError>
            )}
          </FormField>
          {publicQuizId && (
            <FormField>
              <Label htmlFor="quizName">Public Quiz Id</Label>
              <UneditableValue>{publicQuizId}</UneditableValue>
            </FormField>
          )}
          <FormField>
            <Label>Owner</Label>
            {isSuperuser && (
              <UserSelect
                name="ownerId"
                defaultValue={{ value: ownerId, label: ownerUsername }}
                onChange={(option) => setFieldValue("ownerId", option.value)}
              />
            )}
            {!isSuperuser && <UneditableValue>{ownerUsername}</UneditableValue>}
          </FormField>
          <RoundHeading>Rounds</RoundHeading>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {rounds.map((round, index) => (
                    <Draggable
                      key={round.id}
                      draggableId={round.id}
                      index={index}
                      isDragDisabled={isStarted}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <RoundListing disabled={isStarted}>
                            <DragWrapper>
                              <Drag size="small" />
                            </DragWrapper>
                            <Link
                              key={round.id}
                              to={`/create-edit-round?quizId=${quizId}&roundNumber=${round.number}`}
                            >
                              Round {round.number}: {round.title}
                            </Link>
                          </RoundListing>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <ButtonWrapper>
            <Link to={`/select-round-template?quizId=${quizId}`}>
              <Button disabled={!quizId || isStarted}>
                {rounds.length === 0 ? "Add First Round" : "Add a Round"}
              </Button>
            </Link>
            <span> or </span>
            <Link to={`/create-edit-round?quizId=${quizId}`}>
              <Button disabled={!quizId || isStarted}>
                {rounds.length === 0 ? "Enter First Round" : "Enter a Round"}
              </Button>
            </Link>
            {!quizId && <FormHint>Save quiz in order to add rounds</FormHint>}
          </ButtonWrapper>
          <RoundHeading>Tiebreaker</RoundHeading>
          {tiebreaker && (
            <TiebreakerListing>
              <Link
                to={`/create-edit-tiebreaker?quizId=${quizId}&tiebreakerId=${tiebreaker.id}`}
              >
                {tiebreaker.text}
              </Link>
            </TiebreakerListing>
          )}
          <ButtonWrapper>
            <Link to={`/select-tiebreaker-template?quizId=${quizId}`}>
              <Button disabled={!quizId || isStarted}>
                {tiebreaker ? "Change Tiebreaker" : "Add a Tiebreaker"}
              </Button>
            </Link>
            {!tiebreaker && (
              <>
                <span> or </span>
                <Link to={`/create-edit-tiebreaker?quizId=${quizId}`}>
                  <Button disabled={!quizId || isStarted}>
                    Enter Tiebreaker
                  </Button>
                </Link>
              </>
            )}
          </ButtonWrapper>
        </Form>
      </FormWrapper>
      <ActionBar>
        <Button size="large" disabled={hosting} onClick={onHostQuiz}>
          {hosting ? "Hosting" : "Host"}
        </Button>
        <Button size="large" onClick={handleGetLinkClick}>
          Invite
        </Button>
        <Button
          size="large"
          disabled={!isChanged() || isSubmitting || isStarted}
          onClick={() => submitForm()}
        >
          {isSubmitting ? "Saving" : "Save"}
        </Button>
        <Button size="large" disabled={!quizId} onClick={handleDuplicateClick}>
          Duplicate
        </Button>
        <Link to="/quiz-list">
          <Button size="large">Close</Button>
        </Link>
        {isStarted && (
          <DangerButton size="large" onClick={handleResetClick}>
            Reset Quiz
          </DangerButton>
        )}
        {!isStarted && (
          <DangerButton
            disabled={!quizId}
            size="large"
            onClick={handleDeleteClick}
          >
            Delete
          </DangerButton>
        )}
      </ActionBar>
      {showGetLinkModal && (
        <GetLinkModal
          publicQuizId={values.publicQuizId}
          onClose={handleCloseGetLink}
        />
      )}
      {showDuplicateModal && (
        <DuplicateQuizModal
          quizId={quizId}
          originalName={originalValues.name}
          onClose={handleCloseDuplicate}
        />
      )}
      {showResetModal && (
        <ResetQuizModal
          publicQuizId={values.publicQuizId}
          onClose={handleCancelReset}
        />
      )}
      {showDeleteModal && (
        <Modal
          title="Delete Quiz"
          text="Delete this quiz and all the related rounds and questions?"
          buttons={
            <>
              <DangerButton
                size="large"
                disabled={isDeleting}
                onClick={handleConfirmDelete}
              >
                {isDeleting ? "Deleting" : "Delete"}
              </DangerButton>
              <Button size="large" onClick={handleCancelDelete}>
                Cancel
              </Button>
            </>
          }
        />
      )}
    </ContentBox>
  );
}

export default function CreateEditQuiz({ quizId }) {
  const { data, refetch } = useQuery(GET_QUIZ, {
    variables: {
      id: quizId,
    },
    skip: !quizId,
  });

  const [hosting, setHosting] = useState(false);

  const [hostQuiz] = useMutation(HOST_QUIZ);
  const [saveQuiz] = useMutation(SAVE_QUIZ);
  const [reorderRounds] = useMutation(REORDER_ROUNDS);
  const [deleteQuiz] = useMutation(DELETE_QUIZ);

  if (quizId && !data) {
    return null;
  }

  const originalValues = (data && data.quiz) || {};

  const quiz = data && data.quiz;
  const publicQuizId = quiz && quiz.publicId;
  const ownerId = quiz && quiz.owner && quiz.owner.id;
  const ownerUsername = quiz && quiz.owner && quiz.owner.username;

  const initialValues = {
    quizName: (data && data.quiz && data.quiz.name) || "",
    publicQuizId: (data && data.quiz && data.quiz.publicId) || "",
    ownerId: ownerId || "",
  };

  const validate = (values) => {
    const errors = {};
    if (!values.quizName) {
      errors.quizName = "You must enter a quiz name";
    }
    return errors;
  };

  const handleSubmit = async (values, { setStatus, setErrors }) => {
    try {
      const result = await saveQuiz({
        variables: {
          id: quizId,
          name: values.quizName,
          ownerId: values.ownerId,
        },
      });
      const newQuizId = result.data.saveQuiz.quiz.id;
      await navigate(`/create-edit-quiz?quizId=${newQuizId}`);
      await refetch({ id: newQuizId });
    } catch (error) {
      Bugsnag.notify(error);

      if (
        error.graphQLErrors[0].message.startsWith(
          "No duplicate publicId values"
        )
      ) {
        setErrors({ publicQuizId: "This quiz ID is already in use" });
      } else {
        setStatus("Unknown error, try again");
      }
    }
  };

  const handleHostQuiz = async () => {
    setHosting(true);
    await hostQuiz({
      variables: {
        publicQuizId: data.quiz.publicId,
      },
    });
    if (DEBUG) {
      await sleep(2000);
    }
    await navigate("/preview-av/");
  };

  const handleReorderRounds = async (roundId, newNumber) => {
    await reorderRounds({
      variables: {
        quizId,
        roundId,
        newNumber,
      },
    });
    await refetch();
  };

  const handleDelete = async () => {
    await deleteQuiz({
      variables: {
        id: quizId,
      },
    });
    await navigate("/quiz-list");
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
    >
      <QuizEditor
        quizId={quizId}
        publicQuizId={publicQuizId}
        ownerId={ownerId}
        ownerUsername={ownerUsername}
        rounds={(data && data.quiz && data.quiz.rounds) || []}
        tiebreaker={data && data.quiz && data.quiz.tiebreaker}
        originalValues={originalValues}
        hosting={hosting}
        isStarted={data && data.quiz && data.quiz.started}
        onHostQuiz={handleHostQuiz}
        onReorderRounds={handleReorderRounds}
        onDelete={handleDelete}
      />
    </Formik>
  );
}
