import React, { useState } from "react";
import Modal from "../Modal";
import Button from "../buttons/Button";
import PrimaryButton from "../buttons/PrimaryButton";
import { Formik } from "formik";
import Form from "../Form";
import FormField from "../FormField";
import Label from "../Label";
import Input from "../Input";
import FormError from "../FormError";
import "emoji-mart/css/emoji-mart.css";
import { gql, useMutation } from "@apollo/client";
import { navigate } from "gatsby";

const DUPLICATE_QUIZ = gql`
  mutation DuplicateQuiz($id: ID!, $newName: String!) {
    duplicateQuiz(id: $id, newName: $newName) {
      quiz {
        id
        name
        publicId
      }
    }
  }
`;

export default ({ quizId, originalName, onClose }) => {
  const [duplicateQuizMutation] = useMutation(DUPLICATE_QUIZ);
  const [submitted, setSubmitted] = useState(false);

  const validate = (values) => {
    const errors = {};
    if (!values.newName) {
      errors.newName = "You must enter a new name";
    }
    if (values.newName === originalName) {
      errors.newName = "You must enter a different name";
    }
    return errors;
  };

  const handleDuplicate = async (values) => {
    setSubmitted(true);
    const result = await duplicateQuizMutation({
      variables: {
        id: quizId,
        newName: values.newName,
      },
    });
    const newQuizId = result.data.duplicateQuiz.quiz.id;
    const newUrl = `/create-edit-quiz?quizId=${newQuizId}`;
    await navigate(newUrl);
    onClose();
  };

  return (
    <Formik
      initialValues={{ newName: originalName }}
      validate={validate}
      onSubmit={handleDuplicate}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Modal
            title="Duplicate Quiz"
            body={
              <>
                <FormField>
                  <Label>New Name</Label>
                  <Input
                    name="newName"
                    autoFocus
                    autoComplete="off"
                    value={values.newName}
                    onChange={handleChange}
                  />
                  {errors.newName && touched.newName && (
                    <FormError>{errors.newName}</FormError>
                  )}
                </FormField>
              </>
            }
            buttons={
              <>
                <PrimaryButton
                  type="submit"
                  size="large"
                  disabled={isSubmitting || submitted}
                >
                  {isSubmitting || submitted ? "Duplicating" : "Duplicate"}
                </PrimaryButton>
                <Button size="large" onClick={onClose}>
                  Cancel
                </Button>
              </>
            }
          />
        </Form>
      )}
    </Formik>
  );
};
