import React, { useState } from "react";
import Modal from "../Modal";
import DangerButton from "../buttons/DangerButton";
import OurButton from "../buttons/Button";
import { navigate } from "gatsby";
import { gql, useMutation } from "@apollo/client";

const RESET_QUIZ = gql`
  mutation ResetQuiz($publicQuizId: String!) {
    resetQuiz(publicQuizId: $publicQuizId) {
      quiz {
        id
        started
        whatToShow
      }
    }
  }
`;

export default ({ publicQuizId, onClose }) => {
  const [resetQuizMutation] = useMutation(RESET_QUIZ);

  const [submitted, setSubmitted] = useState(false);

  const handleResetQuiz = async () => {
    setSubmitted(true);
    await resetQuizMutation({
      variables: {
        publicQuizId,
      },
    });
    await navigate("/");
  };

  return (
    <Modal
      title="Reset Quiz"
      text="Remove all teams and scores from this quiz?"
      buttons={
        <>
          <DangerButton
            size="large"
            onClick={handleResetQuiz}
            disabled={submitted}
          >
            {submitted ? "Resetting" : "Reset"}
          </DangerButton>
          <OurButton size="large" onClick={onClose}>
            Cancel
          </OurButton>
        </>
      }
    />
  );
};
